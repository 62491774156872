<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal width="800px">
			<template #modal-title>
				Edit Related Double Opt In Channel
			</template>
			<template #modal-content>
				<v-row dense>
					<v-col :cols="6">
						<TextField
							v-model="channel.channelName"
							:label="'Channel Name *' | useLabels"
							rules="required"
						/>
					</v-col>
					<v-col :cols="6">
						<Combobox
							v-model="channel.channelCategory"
							:items="channelCategories"
							:label="'Channel Category Name *' | useLabels"
							rules="required"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col :cols="12">
						<TextArea
							v-model="channel.channelDescription"
							:label="'Channel Description' | useLabels"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col :cols="6">
						<TextField
							v-model="channel.expiryDays"
							label="Consent Expiry (in Days)"
							type="number"
							rules="numeric"
						/>
					</v-col>
					<v-col :cols="6">
						<Dropdown
							v-if="enableTransactionTypes"
							v-model="channel.transactionTypeId"
							:items="transactionTypeOptions"
							:label="'Transaction Type *' | useLabels"
							rules="required"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="enableTransactionTypes"
					dense
				>
					<v-col
						v-if="isAccessibleTransactionType"
						:cols="6"
					>
						<Toggle
							:value="channel.allowUniversalConsent"
							:label="universalConsentInfo.label"
							:disabled="!universalConsentToggleEnabled"
							:tooltip-text="universalConsentInfo.tooltip"
							@update:value="val => channel.allowUniversalConsent = val"
						/>
					</v-col>
				</v-row>
				<MetadataCard
					module-type="Channel"
					:component-type-id="COMPONENT_TYPE.CHANNELS"
					:assigned-meta-data="channel.metaData"
					:flat="true"
					:background="true"
					@update:metadata="updateMetadata($event)"
				/>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton @click="handleSubmit(saveChanges)">
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import Modal from '../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import MetadataCard from '../../shared/metadata-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import TextArea from '../../../../../shared/components/textarea.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import Combobox from '../../../../../shared/components/combobox.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import { COMPONENT_TYPE } from '../../../../../shared/state/meta-data.js'

export default {
	name: 'manage-related-channel-modal',
	components: { PrimaryActionButton, SecondaryActionButton, Modal, MetadataCard, TextField, TextArea, Dropdown, Combobox, Toggle },
	props: {
		relatedChannel: Object,
		enableTransactionTypes: Boolean,
		transactionTypes: Array,
		channelCategories: Array
	},
	setup () {
		return {
			COMPONENT_TYPE
		}
	},
	data () {
		return {
			channel: JSON.parse(JSON.stringify(this.relatedChannel))
		}
	},
	computed: {
		isAccessibleTransactionType () {
			const transactionType = this.transactionTypes.find(type => type.value === this.channel.transactionTypeId)
			return transactionType && transactionType.securityLevelId === 1
		},
		universalConsentInfo () {
			const transactionType = this.transactionTypes.find(type => type.value === this.channel.transactionTypeId)
			let label = ''
			let tooltip = ''
			if (transactionType) {
				label = transactionType.enableUniversalConsent
					? 'Override Universal Consent'
					: 'Allow Universal Consent'
				tooltip = transactionType.enableUniversalConsent
					? 'This chosen Transaction Type has Universal Consent enabled. Use this toggle to override this functionality for this particular Channel'
					: 'This chosen Transaction Type has Universal Consent disabled. Use this toggle to allow this functionality for this particular Channel'
			}
			return { label, tooltip }
		},
		universalConsentToggleEnabled () {
			const transactionType = this.transactionTypes.find(type => type.value === this.channel.transactionTypeId)
			return transactionType && (transactionType.securityLevelId === 1)
		},
		transactionTypeOptions () {
			return this.transactionTypes.map(type => ({
				value: type.value,
				text: type.text
			}))
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		saveChanges () {
			this.$emit('save', this.channel)
			this.close()
		},
		updateMetadata (assignedMetadata) {
			this.channel.metaData = assignedMetadata
		}
	}
}
</script>
