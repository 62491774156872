<template>
	<validation-provider
		#default="{ errors }"
		ref="customValidator"
		:name="fieldName || $props.label"
		:rules="rules"
		:mode="validationMode"
		:custom-messages="customMessages"
		:skip-if-empty="false"
		tag="div"
	>
		<v-combobox
			ref="select"
			:label="label"
			v-bind="$props"
			:readonly="viewOnly"
			:persistent-placeholder="viewOnly"
			:error-messages="errors.length ? [errors[0]] : []"
			:rules="[]"
			:hide-details="!hasRules || !errors.length"
			:class="[small ? 'cassie-dropdown--small' : '', getQaSelectorClass($props)]"
			:menu-props="{
				contentClass: `dropdown-menu cassie-dropdown ${small ? 'cassie-dropdown--small' : '' }`
			}"
			dense
			class="cassie-dropdown"
			v-on="$listeners"
		/>
	</validation-provider>
</template>

<script>
import { VCombobox } from 'vuetify/lib'
import FieldValidationMixin from '../validation/field-validation-mixin.js'

export default {
	name: 'combobox',
	extends: VCombobox,
	mixins: [FieldValidationMixin],
	props: {
		label: {
			type: String
		},
		viewOnly: {
			type: Boolean,
			default: false
		},
		light: {
			type: Boolean,
			default: true
		},
		outlined: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		validateOnShow: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			menuOpen: false
		}
	},
	mounted () {
		this.validateOnShow && this.$refs.customValidator.validate()
	},
	created () {
		window.addEventListener('scroll', this.closeMenuOnScroll, true)
		window.addEventListener('click', this.closeMenu, true)
	},
	destroyed () {
		window.removeEventListener('scroll', this.closeMenuOnScroll, true)
		window.removeEventListener('click', this.closeMenu, true)
	},
	methods: {
		getQaSelectorClass (props) {
			let trimmedLabel = props.label
			if (trimmedLabel) {
				if (trimmedLabel.includes(' *')) {
					trimmedLabel = trimmedLabel.replace(' *', '')
				}
				trimmedLabel = trimmedLabel.replace(/\s/g, '-').toLowerCase()
			}
			return `qa-combobox-${trimmedLabel}`
		},
		closeMenuOnScroll (event) {
			if (this.menuOpen && !event.target.closest('.dropdown-menu')) this.closeMenu(event)
		},
		async closeMenu (event) {
			if ((!event.target.closest('.dropdown-menu') || !this.multiple) && this.menuOpen) {
				this.menuOpen = false
				this.$refs.select.blur()
			}
		}
	}
}
</script>

<style lang="scss">
	.cassie-dropdown {
		.v-select-list .v-list-item .v-list-item__action {
			margin: 5px 10px 0 0 !important;
		}

		&--small {
			.v-input__control .v-input__slot {
				height: 32px !important;
				padding: 0 8px !important;
				min-height: 0 !important;
			}

			.v-select-list .v-list-item {
				min-height: 0 !important;
			}

			.v-select-list .v-list-item .v-list-item__content {
				padding: 4px 0 !important;
			}

			.v-select__selection.v-select__selection {
				font-size: 85% !important;
				margin-left: 6px;
			}

			.v-input__append-inner {
				margin-top: 4px !important;
			}

			.v-input__control .v-input__slot label {
				margin-top: -12px !important;
			}

			.v-label {
				top: 18px !important;
				font-size: 14px !important;
			}

			.v-input__control .v-input__slot label.v-label--active {
				margin: 0 0 0 5px !important;
			}

			.v-label--active {
				-webkit-transform: translateY(-25px) scale(0.65) !important;
				transform: translateY(-25px) scale(0.65) !important;
			}
		}
	}
</style>
