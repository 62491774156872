<template>
	<ValidationForm
		id="manage_channel_statement_container"
		#default="{ handleSubmit }"
		tag="div"
		style="width: 100%; overflow-y: auto"
	>
		<ChannelsLayout
			v-if="!showStatementScreen"
		>
			<template
				#content
				class="cassie-vertical-md"
			>
				<SectionCard>
					<template #title>
						Channel Details
					</template>
					<template #subtitle>
						Input the Name & Description of the Channel.  The Channel also needs to be associated to a Brand and Channel Category
					</template>
					<template #body>
						<v-row dense>
							<v-col :cols="6">
								<TextField
									v-model="channel.channelName"
									:label="'Channel Name *' | useLabels"
									rules="required"
									:disabled="universalChannelNotUser || !channelsFullPermissions"
								/>
							</v-col>
							<v-col :cols="6">
								<Combobox
									v-model="channel.channelCategory"
									:items="channelCategories"
									:label="'Channel Category Name *' | useLabels"
									rules="required"
									:disabled="universalChannelNotUser || !channelsFullPermissions"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col :cols="6">
								<Dropdown
									v-model="channel.brandId"
									:items="filteredBrands"
									custom-sort
									:label="`Brand *` | useLabels"
									:rules="{ required: clientMultiBranded}"
									:disabled="universalChannelNotUser || !channelsFullPermissions || !clientMultiBranded || singleBrand"
								/>
							</v-col>
						</v-row>
						<TextArea
							v-model="channel.channelDescription"
							:label="'Channel Description' | useLabels"
							:disabled="universalChannelNotUser || !channelsFullPermissions"
						/>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Channel Configuration
					</template>
					<template #subtitle>
						Set the type of Channel and how long the consent is valid for by default
					</template>
					<template #body>
						<v-row>
							<v-col :cols="6">
								<Dropdown
									v-model="channel.channelType.typeID"
									:items="channelTypeOptions"
									:label="'Channel Type *' | useLabels"
									rules="required"
									:tooltip-text="channelTypeTooltip"
									:disabled="universalChannelNotUser || !channelsFullPermissions"
								/>
							</v-col>
							<v-col :cols="6">
								<TextField
									v-model="channel.expiryDays"
									label="Consent Expiry (in Days)"
									type="number"
									rules="numeric"
									tooltip-text="If an explicit consent expiry is not passed in with each submission, Cassie will use this value to calculate the expiry date"
									:disabled="universalChannelNotUser || !channelsFullPermissions"
								/>
							</v-col>
						</v-row>
						<v-row
							v-if="channel.channelType.typeID === CHILD_CHANNEL_TYPE_ID"
						>
							<v-col :cols="6">
								<Dropdown
									:value="channel.channelType ? channel.channelType.parentChannelID : null"
									:items="parentChannelOptions"
									:label="'Parent Channel *' | useLabels"
									rules="required"
									:disabled="universalChannelNotUser || !channelsFullPermissions"
									@input="$set(channel.channelType, 'parentChannelID', $event)"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						{{ 'Statements' | useLabels }}
					</template>
					<template #title-action>
						<TextButton
							v-if="(showStatementDrafts.value === false || canCreateDraftStatements && !universalChannelNotUser) && statementsFullPermissions"
							@click="onShowStatementScreen"
						>
							<v-icon
								left
								dark
							>
								{{ !statementsFullPermissions ? '' : 'mdi-plus' }}
							</v-icon>
							Add {{ 'Statement' | useLabels }}
						</TextButton>
					</template>
					<template #subtitle>
						Manage the Statements associated to this Channel
					</template>
					<template #body>
						<div
							class="d-flex align-center cassie-horizontal-md"
							style="flex: 1;"
						>
							<SecondaryActionButton
								:input-value="showFilters"
								@click="showFilters = !showFilters"
							>
								<v-icon>
									mdi-filter
								</v-icon>
								Filter
							</SecondaryActionButton>
							<template v-if="showFilters">
								<Dropdown
									v-model="selectedMetaDataKeyId"
									label="Metadata Key"
									:items="metaDataKeyItems"
								/>
								<TextField
									v-model="metaDataSearchQuery"
									label="Metadata Value"
								/>
							</template>
						</div>
						<DataTable
							:headers="tableHeaders"
							:items="statements"
							@click:row="(item, index) => onStatementRowClick(item, index)"
						>
							<template #item.statementID="{ item }">
								{{ item.statementID > 0 ? item.statementID : 'TBC' }}
							</template>
							<template #item.statementText="{ item }">
								{{ getTruncatedText(item.statementText, 100) }}
							</template>
							<template #item.defaultValue="{ item }">
								{{ item.defaultValue ? 'Yes' : 'No' }}
							</template>
							<template #item.isOptIn="{ item }">
								{{ item.isOptIn ? 'Yes' : 'No' }}
							</template>
							<template #item.status="{ item }">
								{{ getStatus(item) }}
							</template>
							<template #item.action="{ item, index }">
								<IconButton
									v-if="!universalChannelNotUser && statementsFullPermissions && item.statementID > 0"
									:tooltip-text="'Edit Statement' | useLabels"
									@click="onEditStatement(item, index)"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									v-if="statementsReadPermissions && !statementsFullPermissions"
									:tooltip-text="'View Statement' | useLabels"
									@click="onEditStatement(item, index)"
								>
									mdi-eye
								</IconButton>
								<IconButton
									v-if="(canDeleteStatements || statementsFullPermissions) && !universalChannelNotUser"
									:tooltip-text="'Delete Statement' | useLabels"
									@click.stop.prevent="onRemoveStatement(item, index)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
				<SectionCard v-if="showTransactionType">
					<template #title>
						Channel Transaction Settings
					</template>
					<template #subtitle>
						Select a Transaction Type and configure the ability to copy Device consent on to a Data Subject record
					</template>
					<template #body>
						<v-row
							dense
						>
							<v-col :cols="6">
								<Dropdown
									v-model="channel.transactionTypeId"
									:items="transactionTypeOptions"
									:label="'Transaction Type *' | useLabels"
									rules="required"
									:disabled="universalChannelNotUser || !channelsFullPermissions"
								/>
							</v-col>
							<v-col
								v-if="isAccessibleTransactionType"
								:cols="6"
							>
								<Toggle
									:value="channel.allowUniversalConsent"
									:label="universalConsentInfo.label"
									:disabled="!universalConsentToggleEnabled"
									:tooltip-text="universalConsentInfo.tooltip"
									@update:value="val => channel.allowUniversalConsent = val"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard v-if="!showStatementDrafts || isEdit">
					<template #title>
						Double Opt In
					</template>
					<template #subtitle>
						Double Opt In can be enabled to ensure that a Data Subject explicitly confirms their intention to Opt In via clicking a Link in an Email
						<br>An active Statement is required to use Double Opt In
					</template>
					<template #body>
						<v-row
							dense
						>
							<v-col :cols="6">
								<Toggle
									:value.sync="channel.channelType.doubleOptInEligible"
									:label="'Is this Channel eligible for Double Opt In?' | useLabels"
									:disabled="universalChannelNotUser || !channelsFullPermissions"
									tooltip-text="Creating a Channel eligible for Double Opt In will create two copies of this Channel, one for the Preliminary Consent trigger and the Primary Channel to store consent against once the Data Subject is identified"
								/>
							</v-col>
						</v-row>
						<template v-if="isDoubleOptInChannel">
							Select the System Email a Data Subject will receive if they Opt In to a Channel with Double Opt In enforced
							<v-row>
								<v-col :cols="6">
									<Dropdown
										:value="relatedChannel.channelType ? relatedChannel.channelType.doubleOptInConfirmationEmailID : null"
										:items="confirmationEmailItems"
										label="Confirmation Email"
										:disabled="universalChannelNotUser || !channelsFullPermissions"
										tooltip-text="Select the Email that the Data Subject will receive to confirm the Opt-In status.<br><br>
											Do not make a selection if this is being handled outside of Cassie"
										clearable
										@input="updateConfirmationEmail"
									/>
								</v-col>
							</v-row>
							Select the Statement the Data Subject will be asked to click in the selected System Email
							<v-row>
								<v-col :cols="6">
									<Dropdown
										:value="doubleOptInLink.actionLinkConfig ? doubleOptInLink.actionLinkConfig.actionLinkStatementId : null"
										:items="doubleOptInStatements"
										label="Double Opt In Statement Text *"
										:disabled="universalChannelNotUser || !channelsFullPermissions"
										tooltip-text="This is the Statement which will appear in the Cassie System Email if selected. A Statement is a mandatory selection to use Double Opt In"
										rules="required"
										@input="updateActionLinkStatementId"
									/>
								</v-col>
								<v-col :cols="6">
									<TextButton
										v-if="(showStatementDrafts.value === false || canCreateDraftStatements && !universalChannelNotUser) && statementsFullPermissions"
										@click="onShowStatementScreen(SET_NEW_STATEMENT_AS_DOUBLE_OPT_IN_STATEMENT)"
									>
										<v-icon
											left
											dark
										>
											{{ !statementsFullPermissions ? '' : 'mdi-plus' }}
										</v-icon>
										Add {{ 'Statement' | useLabels }}
									</TextButton>
								</v-col>
							</v-row>
							<template v-if="relatedChannel.channelType.doubleOptInConfirmationEmailID || channel.channelType.doubleOptInConfirmationEmailID">
								<v-row dense>
									<v-col :cols="12">
										<div class="text-subtitle-1 black--text font-weight-medium">
											Outcomes
										</div>
									</v-col>
								</v-row>
								<v-divider />
								<v-row dense>
									<v-col :cols="12">
										<div>
											Select where you would like the end user to be taken once they have clicked the Double Opt In Cassie Action Link
										</div>
									</v-col>
								</v-row>
								<v-row>
									<v-col :cols="6">
										<Dropdown
											:value="confirmationPageTypeId"
											:items="successOutcomeOptions"
											:disabled="universalChannelNotUser || !channelsFullPermissions"
											:tooltip-text="successfulOutcomeTooltip"
											item-value="value"
											label="Successful Outcome *"
											rules="required"
											@input="$set(doubleOptInLink.configurationOptions.successOutcome, 'confirmationPageTypeId', $event)"
										/>
									</v-col>
									<v-col
										v-if="confirmationPageTypeId === CONFIRMATION_PAGE_OUTCOME"
										:cols="6"
									>
										<Dropdown
											:value="doubleOptInLink.configurationOptions.successOutcome.internalConfirmationPageId"
											:items="brandFilteredConfirmationPages.map(({ confirmationPageId, confirmationPageName }) => ({ value: confirmationPageId, text: confirmationPageName }))"
											:disabled="universalChannelNotUser || !channelsFullPermissions"
											label="Select Confirmation Page *"
											rules="required"
											@input="$set(doubleOptInLink.configurationOptions.successOutcome, 'internalConfirmationPageId', $event)"
										/>
									</v-col>
									<v-col
										v-if="confirmationPageTypeId === URL_OUTCOME"
										:cols="6"
									>
										<TextField
											:value="doubleOptInLink.configurationOptions.successOutcome.externalConfirmationPageUrl"
											:disabled="universalChannelNotUser || !channelsFullPermissions"
											label="Select Destination URL *"
											:rules="{required: true, isUrlwithHttp: true, max: 512}"
											@input="$set(doubleOptInLink.configurationOptions.successOutcome, 'externalConfirmationPageUrl', $event)"
										/>
									</v-col>
								</v-row>
								<v-row>
									<v-col :cols="6">
										<Dropdown
											v-model="selectedFailureOutcomeOption"
											:items="failureOutcomeOptions"
											:disabled="universalChannelNotUser || !channelsFullPermissions"
											item-value="value"
											label="Failure Outcome *"
											:tooltip-text="failureOutcomeTooltip"
											rules="required"
											@input="$set(doubleOptInLink, 'failureType', $event)"
										/>
									</v-col>
									<v-col
										v-if="selectedFailureOutcomeOption === FAILURE_PAGE_OUTCOME"
										:cols="6"
									>
										<TextField

											:disabled="universalChannelNotUser || !channelsFullPermissions"
											label="Failure Page *"
											value="Default Failure Page"
											view-only
											rules="required"
										/>
									</v-col>
									<v-col
										v-if="selectedFailureOutcomeOption === URL_OUTCOME"
										:cols="6"
									>
										<TextField
											:disabled="universalChannelNotUser || !channelsFullPermissions"
											:value="doubleOptInLink.customErrorUrl"
											label="Failure Destination URL *"
											:rules="{required: true, isUrlwithHttp: true, max: 512}"
											@input="$set(doubleOptInLink, 'customErrorUrl', $event)"
										/>
									</v-col>
								</v-row>
							</template>
						</template>
						<template v-if="relatedChannel.channelId && isDoubleOptInChannel">
							<v-row dense>
								<v-col :cols="12">
									<div class="text-subtitle-1 black--text font-weight-medium">
										Related Channel
									</div>
								</v-col>
							</v-row>
							<v-divider />
							Manage a Linked Double Opt In Channel.  This Channel is automatically created once a Channel is set as eligible for Double Opt In
							<DataTable
								:items="[relatedChannel]"
								:headers="relatedChannelHeaders"
								:disable-sort="true"
								hide-default-footer
							>
								<template #item.action>
									<IconButton
										v-if="channelsFullPermissions || !universalChannelNotUser"
										small-icon
										small
										@click="showRelatedChannelEdit"
									>
										mdi-pencil
									</IconButton>
								</template>
							</DataTable>
						</template>
					</template>
				</SectionCard>
				<MetadataCard
					module-type="Channel"
					:component-type-id="COMPONENT_TYPE.CHANNELS"
					:assigned-meta-data="channel.metaData"
					:read-only="universalChannelNotUser || !channelsFullPermissions"
					@update:metadata="updateMetadata($event)"
				/>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="statementsFullPermissions"
							@click="handleSubmit(submitChannel)"
						>
							Save
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ChannelsLayout>
		<ManageStatement
			v-else
			:statement-to-edit="statementToEdit"
			:index="selectedIndex"
			:channel-name="channel.channelName"
			:channel-id="channel.channelId"
			:channel-statements="channel.statements"
			accessed-via="channel"
			@load-channel-statements="loadStatements"
			@update:statements="updateStatements"
			@update:edit-channel-new-statement-added="newStatementId = $event"
			@close="() => {
				statementToEdit = null,
				showStatementScreen = false
			}"
		/>
		<ManageRelatedChannel
			v-if="showRelatedChannelModal"
			:related-channel="relatedChannel"
			:enable-transaction-types="enableTransactionTypes"
			:transaction-types="transactionTypeOptions"
			:channel-categories="channelCategories"
			@save="submitRelatedChannel($event)"
			@close="() => {
				showRelatedChannelModal = false
			}"
		/>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../shared/utils/date-formatting.js'
import { CHANNELS } from '../../../router/route-names.js'
import MetadataCard from '../../shared/metadata-card.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import ChannelsLayout from './channels-layout.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import TextArea from '../../../../../shared/components/textarea.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import Combobox from '../../../../../shared/components/combobox.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import ManageStatement from '../statements/manage-statement/_manage-statement.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import ManageRelatedChannel from './manage-related-channel-modal.vue'
import { getPreferenceChannels, createChannel, updateChannel, createChannelWithStatementsAndActions } from '../../../../../shared/utils/api/channels.js'
import { deleteStatement, getStatements } from '../../../../../shared/utils/api/statements.js'
import { getExternalMetaData } from '../../../../../shared/utils/api/external-meta-data.js'
import { getSystemEmails } from '../../../../../shared/utils/api/system-emails.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM } from '../../../../../shared/state/brands.js'
import { COMPONENT_TYPE } from '../../../../../shared/state/meta-data.js'
import { licenseId, showStatementDrafts, enableTransactionTypes } from '../../../../../shared/state/config-keys.js'
import { channelTypeOptions, CHILD_CHANNEL_TYPE_ID, PARENT_CHANNEL_TYPE_ID } from './channel-type-options.js'
import { CAN_DELETE_STATEMENTS, CAN_CREATE_DRAFT_STATEMENTS, COMPONENTS_MODULE_FULL_PERMISSIONS, CHANNELS_FULL_PERMISSIONS, STATEMENTS_FULL_PERMISSIONS, CAN_SEE_STATEMENTS_PAGE } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { STATEMENT_STATUS } from '../statements/statement-enums.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'
import { getTransactionTypes } from '../../../../../shared/utils/api/transaction-types.js'
import { upsertActionLink, getLinkByID, getLinks } from '../../../../../shared/utils/api/admin-portal/links/links.js'
import { getEnv } from '../../../../../shared/utils/runtime-settings.js'

const widgetUrl = getEnv('VUE_APP_WIDGETHANDLER')
const CONFIRMATION_PAGE_OUTCOME = 2
const FAILURE_PAGE_OUTCOME = 2
const FAILURE_URL = 'Forward To URL'
const URL_OUTCOME = 1
const REMAIN_ON_PAGE_OUTCOME = 3 // TBC
const SET_NEW_STATEMENT_AS_DOUBLE_OPT_IN_STATEMENT = true

export default {
	components: {
		DataTable,
		Dropdown,
		TextButton,
		TextArea,
		TextField,
		PrimaryActionButton,
		SecondaryActionButton,
		PageActionRow,
		ChannelsLayout,
		SectionCard,
		MetadataCard,
		ManageStatement,
		IconButton,
		ValidationForm,
		Combobox,
		Toggle,
		ManageRelatedChannel
	},
	props: {
		channelToEdit: Object
	},
	setup () {
		return {
			brandOptions,
			showStatementDrafts,
			COMPONENT_TYPE,
			clientMultiBranded,
			defaultBrandInSelect,
			singleBrand,
			getTruncatedText,
			enableTransactionTypes,
			licenseId
		}
	},
	data () {
		return {
			channel: JSON.parse(JSON.stringify(this.channelToEdit || {
				channelName: '',
				channelCategory: '',
				brandId: this.defaultBrandInSelect,
				channelDescription: '',
				channelType: {},
				expiryDays: null,
				statements: [],
				metaData: [],
				transactionTypeId: null,
				allowUniversalConsent: false
			})),
			transactionTypeOptions: [],
			showAddStatementModal: false,
			showFilters: false,
			selectedMetaDataKeyId: null,
			metaDataSearchQuery: '',
			showStatementScreen: false,
			statementToEdit: null,
			selectedIndex: null,
			channelTypeOptions,
			channels: [],
			channelCategories: [],
			CHILD_CHANNEL_TYPE_ID,
			PARENT_CHANNEL_TYPE_ID,
			systemEmails: [],
			metaDataKeys: [],
			allStatements: [],
			relatedChannel: {
				channelName: '',
				channelCategory: '',
				brandId: this.defaultBrandInSelect,
				channelDescription: '',
				channelType: {},
				expiryDays: null,
				statements: [],
				metaData: [],
				transactionTypeId: null,
				allowUniversalConsent: false
			},
			showRelatedChannelModal: false,
			doubleOptInLink: {
				actionLInkId: null,
				linkName: null,
				linkText: `<a href="${widgetUrl}?action=emailLink&LicenseID=${licenseId.value}&key=[Double_Opt_In_Replace_Field]&keyName=SyrenisID&gd=[Double_Opt_In_Replace_LinkGuid]&sourcecode=sc&responsechannel=rc&languagecode=[REPLACE_LANGUAGECODE]">[REPLACE_STATEMENT]</a>`,
				footerContentId: null,
				brandId: 0,
				actionLinkEnforceDoubleOptIn: false,
				customDefaultLanguageId: 0,
				themeId: null,
				authenticationDetails: {
					authTypeId: 1,
					standDakAuthField: 'Email1'
				},
				propertyFields: {
					sourceCodeReplaceValue: 'SC',
					responseCodeReplaceValue: 'RC'
				},
				linkFields: [
					{
						friendlyNameInLink: 'SyrenisID',
						isEncrypted: false,
						isMatchField: true,
						replaceValueInLink: '[Double_Opt_In_Replace_Field]',
						standdakFieldName: 'syrenisid',
						previewValue: null
					}
				],
				configurationOptions: {
					createStanddakIfNoMatch: false,
					multiStandDakMatchIsFailure: false,
					ipLimitations: {
						noOfSubmissionsAllowedFromIp: 10,
						iplimitPeriodInMins: 0,
						limitSubmissionsByIptypeId: 1
					},
					encryption: {
						encryptionKeyId: null
					},
					successOutcome: {
					},
					actionLinkConfig: {
						actionLinkStatementId: null,
						extendedPreference: []
					}
				}
			},
			confirmationPages: [],
			CONFIRMATION_PAGE_OUTCOME,
			URL_OUTCOME,
			FAILURE_PAGE_OUTCOME,
			FAILURE_URL,
			selectedFailureOutcomeOption: null,
			setDoubleOptInStatement: false,
			SET_NEW_STATEMENT_AS_DOUBLE_OPT_IN_STATEMENT,
			newStatementId: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		isEdit () {
			return Boolean(this.channelToEdit)
		},
		channelsFullPermissions () {
			return this.checkPermission(CHANNELS_FULL_PERMISSIONS) || this.checkPermission(COMPONENTS_MODULE_FULL_PERMISSIONS)
		},
		statementsFullPermissions () {
			return this.checkPermission(STATEMENTS_FULL_PERMISSIONS) || this.checkPermission(COMPONENTS_MODULE_FULL_PERMISSIONS)
		},
		statementsReadPermissions () {
			return this.checkPermission(CAN_SEE_STATEMENTS_PAGE)
		},
		tableHeaders () {
			let tableHeaders = [
				{ value: 'statementID', text: useLabels('Statement ID'), width: '10%' },
				{ value: 'statementText', text: useLabels('Statement Text'), width: '45%' },
				{ value: 'isOptIn', text: 'Is Opt-In', width: '10%' },
				{ value: 'defaultValue', text: 'Default Selection', width: '10%' },
				{ value: 'status', text: 'Status', width: '15%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
			if (this.showStatementDrafts === false) tableHeaders = tableHeaders.filter(({ value }) => value !== 'status')

			return tableHeaders
		},
		filteredBrands () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		},
		statements () {
			if (this.isEdit) {
				return this.channelStatements?.filter(({ metaData }) => {
					let check = true
					if (this.selectedMetaDataKeyId !== null) check = check && metaData.some(meta => meta.metaDataKeyId === this.selectedMetaDataKeyId)
					if (this.metaDataSearchQuery) check = check && metaData.some(meta => meta.metaDataValue.toLowerCase().includes(this.metaDataSearchQuery.toLowerCase()))
					return check
				})
			} else {
				return this.channel.statements?.filter(({ metaData }) => {
					let check = true
					if (this.selectedMetaDataKeyId !== null) check = check && metaData.some(meta => meta.metaDataKeyId === this.selectedMetaDataKeyId)
					if (this.metaDataSearchQuery) check = check && metaData.some(meta => meta.metaDataValue.toLowerCase().includes(this.metaDataSearchQuery.toLowerCase()))
					return check
				})
			}
		},
		channelStatements () {
			return this.allStatements.filter(({ channelID }) => channelID === this.channel.channelId)
		},
		parentChannelOptions () {
			return this.channels.filter(channel => channel?.channelType?.typeID === PARENT_CHANNEL_TYPE_ID &&
			channel?.brandId === this.channel.brandId &&
			channel?.channelType?.doubleOptInRelatedChannelID === 0 &&
			channel?.channelId !== this.channel.channelId)
				.map(({ channelId, channelName }) => ({ value: channelId, text: channelName }))
		},
		channelOptions () {
			return this.channels.map(({ channelId, channelName }) => ({ value: channelId, text: channelName }))
		},
		confirmationEmailItems () {
			const filteredEmails = this.systemEmails.filter(e => e.emailTypeId === 1 || e.emailTypeId === null)
			return filteredEmails.map(({ systemEmailName, systemEmailId }) => ({
				text: systemEmailName,
				value: systemEmailId
			}))
		},
		metaDataKeyItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				...this.metaDataKeys.map(metaDataKey => ({
					value: metaDataKey.id,
					text: metaDataKey.name
				}))
			]
		},
		canDeleteStatements () {
			return this.productAreaPermission(CAN_DELETE_STATEMENTS) || this.checkPermission(COMPONENTS_MODULE_FULL_PERMISSIONS) || this.checkPermission(STATEMENTS_FULL_PERMISSIONS)
		},
		canCreateDraftStatements () {
			return this.productAreaPermission(CAN_CREATE_DRAFT_STATEMENTS)
		},
		universalChannelNotUser () {
			return this.isEdit && !this.userHasUniversalBrand && this.channel.brandId === 0
		},
		isAccessibleTransactionType () {
			const transactionType = this.transactionTypeOptions.find(type => type.value === this.channel.transactionTypeId)
			return transactionType && transactionType.securityLevelId === 1
		},
		universalConsentInfo () {
			const transactionType = this.transactionTypeOptions.find(type => type.value === this.channel.transactionTypeId)
			let label = ''
			let tooltip = ''
			if (transactionType) {
				label = transactionType.enableUniversalConsent
					? 'Override Universal Consent'
					: 'Allow Universal Consent'
				tooltip = transactionType.enableUniversalConsent
					? 'This chosen Transaction Type has Universal Consent enabled. Use this toggle to override this functionality for this particular Channel'
					: 'This chosen Transaction Type has Universal Consent disabled. Use this toggle to allow this functionality for this particular Channel'
			}
			return { label, tooltip }
		},
		universalConsentToggleEnabled () {
			const transactionType = this.transactionTypeOptions.find(type => type.value === this.channel.transactionTypeId)
			return transactionType && (transactionType.securityLevelId === 1)
		},
		showTransactionType () {
			return enableTransactionTypes.value
		},
		isDoubleOptInChannel () {
			return this.channel.channelType.doubleOptInEligible === true
		},
		relatedChannelHeaders () {
			return [
				{
					text: 'Channel ID',
					value: 'channelId'
				},
				{
					text: 'Channel Name',
					value: 'channelName'
				},
				{
					text: 'Created Date',
					value: 'createdDate'
				},
				{
					text: 'Action',
					value: 'action'
				}
			]
		},
		doubleOptInStatements () {
			if (!this.isEdit) {
				return this.channel.statements.filter(statement => statement.statementText).map(statement => ({
					value: statement.statementID,
					text: statement.statementText
				}))
			}
			return this.channel.statements.filter(statement => statement.liveStatementText).map(statement => ({
				value: statement.statementID,
				text: statement.statementText
			}))
		},
		successfulOutcomeTooltip () {
			switch (this.confirmationPageTypeId) {
				case CONFIRMATION_PAGE_OUTCOME:
					return 'Following a successful Preference update, the end user will be redirected to the chosen Confirmation Page'
				case URL_OUTCOME:
					return 'Following a successful Preference update, the end user will be redirected to the Destination URL specified.  Note this must begin with http:// or https://'
				case REMAIN_ON_PAGE_OUTCOME:
					return `Following a successful Preference update the end user will remain on the page and receive an optional Preference Updated 'success' message confirming the submission has been received.`
				default:
					return 'Select the type of response if the Link update is successful'
			}
		},
		failureOutcomeTooltip () {
			switch (this.selectedFailureOutcomeOption) {
				case FAILURE_PAGE_OUTCOME:
					return 'If the update is unsuccessful, the end user will be redirected to a Cassie default error page'
				case URL_OUTCOME:
					return 'If the update is unsuccessful, the end user will be redirected to the Destination URL specified.  Note this must begin with http:// or https://'
				case REMAIN_ON_PAGE_OUTCOME:
					return 'If the update is unsuccessful, the end user will remain on the page and receiving a plain text failure message.'
				default:
					return 'Select the type of response if the Link update fails'
			}
		},
		failureOutcomeOptions () {
			return [
				{ value: FAILURE_PAGE_OUTCOME, text: 'Forward to Failure Page' },
				{ value: URL_OUTCOME, text: 'Forward to URL' }
			]
		},
		confirmationPageTypeId () {
			return this.doubleOptInLink.configurationOptions.successOutcome?.confirmationPageTypeId
		},
		successOutcomeOptions () {
			return [
				{ value: CONFIRMATION_PAGE_OUTCOME, text: 'Forward to Confirmation Page' },
				{ value: URL_OUTCOME, text: 'Forward to URL' }
			]
		},
		brandFilteredConfirmationPages () {
			if (!this.confirmationPages) {
				return []
			}
			return this.confirmationPages.filter(({ brandId }) => brandId === 0 || brandId === this.brandId)
		},
		channelTypeTooltip () {
			switch (this.channel.channelType.typeID) {
				case PARENT_CHANNEL_TYPE_ID:
					return 'A Parent Channel can be associated with multiple Child Channels.  Any preference updates against the Parent Channel will also update the associated Child Channels'
				case CHILD_CHANNEL_TYPE_ID:
					return 'A Child Channel falls under the hierarchy of a chosen Parent Channel.  Any preference logged against a Parent Channel will also update the associated Child Channels'
				default:
					return 'Select a Channel Type'
			}
		}
	},
	watch: {
		enableTransactionTypes: {
			handler (val) {
				if (!val) {
					this.channel.transactionTypeId = null
					this.channel.allowUniversalConsent = false
				}
			},
			immediate: true
		}
	},
	async created () {
		await this.loadChannels()
		this.loadSystemEmails()
		this.loadStatements()
		this.loadTransactionTypes()
		const { externalMetaData } = await getExternalMetaData()
		this.metaDataKeys = externalMetaData
		if (!enableTransactionTypes.value) {
			this.channel.transactionTypeId = null
			this.channel.allowUniversalConsent = false
		}
		this.findRelatedChannel()
		await this.relatedLink()
		if (this.doubleOptInLink.customErrorUrl) {
			this.selectedFailureOutcomeOption = URL_OUTCOME
		} else {
			this.selectedFailureOutcomeOption = FAILURE_PAGE_OUTCOME
		}
	},
	methods: {
		async reloadChannel () {
			await this.loadChannels()
			this.channel = this.channels.find(({ channelId }) => channelId === this.channel.channelId)
			await this.loadStatements()
			this.findRelatedChannel()
		},
		async loadChannels () {
			const { data: { channels, channelCategories } } = await getPreferenceChannels()
			this.channels = channels
			this.channelCategories = channelCategories
		},
		async loadSystemEmails () {
			const { data } = await getSystemEmails()
			this.systemEmails = data.systemEmails
		},
		async loadStatements () {
			const { data: statements } = await getStatements()
			this.allStatements = statements
			if (this.isEdit && this.newStatementId) {
				this.channel.statements = this.allStatements.filter(({ channelID }) => channelID === this.channel.channelId)
				if (!this.showStatementDrafts && this.setDoubleOptInStatement) {
					this.updateActionLinkStatementId(this.newStatementId)
				}
				this.newStatementId = null
			}
		},
		async loadTransactionTypes () {
			const { data } = await getTransactionTypes()
			this.transactionTypeOptions = data.transactionTypes.map(type => ({
				text: type.name,
				value: type.id,
				securityLevelId: type.securityLevelId,
				enableUniversalConsent: type.enableUniversalConsent
			}))
		},
		navigateToOverview () {
			this.$router.push({ name: CHANNELS })
		},
		onAddStatementClick () {
			this.showAddStatementModal = true
		},
		createChannelPayload () {
			return {
				...this.channel,
				transactionTypeId: this.channel.transactionTypeId || null,
				allowUniversalConsent: this.channel.allowUniversalConsent || false
			}
		},
		async handleDoubleOptInChannel () {
			if (this.doubleOptInLink.linkId != null) {
				this.doubleOptInLink.actionLinkId = this.doubleOptInLink.linkId
			}
			this.doubleOptInLink.linkName = `Double_Opt_In_${this.doubleOptInLink.actionLinkConfig.actionLinkChannelName || ''}_${this.doubleOptInLink.actionLinkConfig.actionLinkChannelId || ''}`
			await upsertActionLink(this.doubleOptInLink)
			if (this.relatedChannel.channelId != null) {
				await updateChannel(this.relatedChannel)
			}
		},
		async handleCreateChannel (payload) {
			if (!this.channel.statements) {
				await createChannel(payload)
			} else {
				await createChannelWithStatementsAndActions(payload).then(response => {
					if (this.isDoubleOptInChannel) {
						this.doubleOptInLink.actionLinkConfig.actionLinkStatementId = response.doubleOptInSelectedStatementId ?? this.doubleOptInLink.actionLinkConfig.actionLinkStatementId
						this.doubleOptInLink.actionLinkConfig.actionLinkChannelId = response.channelId ?? this.doubleOptInLink.actionLinkConfig.actionLinkChannelId
					}
				})
			}
		},
		async submitChannel () {
			if (enableTransactionTypes.value && !this.channel.transactionTypeId) {
				showSnackbar({ color: 'red', text: 'Transaction Type is required when Transaction Types are enabled.' })
				return
			}

			const payload = this.createChannelPayload()

			if (!this.isEdit) {
				await this.handleCreateChannel(payload)
			} else {
				await updateChannel(payload)
			}

			if (this.isDoubleOptInChannel) {
				await this.handleDoubleOptInChannel()
			}

			showSnackbar('Your changes have been saved')
			this.navigateToOverview()
		},
		async submitRelatedChannel (channel) {
			const payload = {
				...channel
			}
			await updateChannel(payload)
			showSnackbar('Your changes have been saved')
			await this.reloadChannel()
		},
		onEditStatement (statement, index) {
			if (!this.statementsReadPermissions) return
			this.statementToEdit = statement
			this.selectedIndex = index === Number ? index : index.index
			this.showStatementScreen = true
		},
		async onRemoveStatement (item, index) {
			if (this.isEdit) {
				await deleteStatement(item.statementID)
				showSnackbar('This statement has been deleted')
				this.reloadChannel()
			} else {
				this.$delete(this.channel.statements, index)
			}
		},
		updateMetadata (assignedMetadata) {
			this.channel.metaData = assignedMetadata
		},
		updateStatements (statements) {
			this.channel.statements = statements
		},
		getDraftStatus (statusCode, queuedActionDateTime) {
			switch (statusCode) {
				case STATEMENT_STATUS.NEW:
					return 'Draft: New'
				case STATEMENT_STATUS.SUBMITTED:
					return 'Draft: Submitted'
				case STATEMENT_STATUS.APPROVED:
					return 'Draft: Approved'
				case STATEMENT_STATUS.AWAITING_PUBLISH:
					return 'Draft: Live on ' + format(new Date(queuedActionDateTime), DATE_TIME_FORMAT_NO_SECONDS)
				default:
					return ''
			}
		},
		getStatus (statement) {
			const liveTranslatedStatementText = statement.liveStatements?.find(({ languageId }) => languageId === 0)?.statementText
			const selectedLanguageDraftStatement = statement.draftStatements?.find(({ languageId }) => languageId === 0)
			const liveStatusText = liveTranslatedStatementText ? 'Published' : ''
			const draftStatusText = selectedLanguageDraftStatement ? this.getDraftStatus(selectedLanguageDraftStatement.draftState, selectedLanguageDraftStatement.queuedActionDateTime) : ''
			const separatorText = liveStatusText.length > 0 && draftStatusText.length > 0 ? ', ' : ''

			return liveStatusText + separatorText + draftStatusText
		},
		updateActionLinkStatementId (value) {
			if (!this.doubleOptInLink.actionLinkConfig) {
				this.$set(this.doubleOptInLink, 'actionLinkConfig', {})
			}

			let statement = this.allStatements.find(({ statementID }) => statementID === value)
			if (statement == null) {
				statement = this.channel.statements.find(({ statementID }) => statementID === value)
				this.channel.doubleOptInSelectedStatementId = value
			}

			Object.assign(this.doubleOptInLink.actionLinkConfig, {
				actionLinkStatementId: value,
				actionLinkStatementText: statement.statementText,
				actionLinkStatementIsOptIn: statement.isOptIn,
				legacyIsOptIn: statement.isOptIn,
				actionLinkChannelId: statement.channelID,
				actionLinkChannelName: statement.channelName || this.channel.channelName,
				isEnabled: false
			})
		},
		async relatedLink () {
			if (this.relatedChannel.channelId != null) {
				await getLinkByID(this.relatedChannel.channelType.doubleOptInRelatedLinkID).then(response => {
					this.doubleOptInLink = JSON.parse(JSON.stringify(response.data.actionLinks[0] || this.doubleOptInLink))
					this.confirmationPages = response.data.lookUpData.confirmationPages
				})
			} else {
				await getLinks().then(response => {
					this.confirmationPages = response.data.lookUpData.confirmationPages
				})
			}
		},
		findRelatedChannel () {
			const relatedChannel = this.channels.find(channel =>
				channel.channelType &&
				channel.channelType.doubleOptInRelatedChannelID === this.channel.channelId)
			if (relatedChannel) {
				this.relatedChannel = relatedChannel
			}
		},
		showRelatedChannelEdit () {
			this.showRelatedChannelModal = true
		},
		updateConfirmationEmail (event) {
			if (this.relatedChannel.channelId == null) {
				this.$set(this.channel.channelType, 'doubleOptInConfirmationEmailID', event)
			} else {
				this.$set(this.relatedChannel.channelType, 'doubleOptInConfirmationEmailID', event)
			}
		},
		onShowStatementScreen (setAsDoubleOptInStatement = false) {
			this.setDoubleOptInStatement = setAsDoubleOptInStatement
			this.showStatementScreen = true
		},
		onStatementRowClick (item, index) {
			if (item.statementID != null && item.statementID > 0) {
				this.onEditStatement(item, index)
			}
		}
	}
}
</script>
